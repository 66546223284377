"use client";

import * as React from "react";
type EventDialogContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  eventData: EventData | null;
  openEventDialog: (event: EventData) => void;
  closeEventDialog: () => void;
  hoveredEventId: string | null;
  setHoveredEventId: (id: string | null) => void;
};

// Type for event data
export type EventData = {
  id: string;
  imageUrl?: string;
  imageTitle?: string;
  date?: string;
  title: string;
  summary: string;
  impact: string;
  sources?: Array<{
    name: string;
    url: string;
  }>;
};
export function EventDialogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [eventData, setEventData] = React.useState<EventData | null>(null);
  const [hoveredEventId, setHoveredEventId] = React.useState<string | null>(null);
  const openEventDialog = (event: EventData) => {
    setEventData(event);
    setIsOpen(true);
  };
  const closeEventDialog = () => {
    setIsOpen(false);
  };
  return <EventDialogContext.Provider value={{
    isOpen,
    setIsOpen,
    eventData,
    openEventDialog,
    closeEventDialog,
    hoveredEventId,
    setHoveredEventId
  }} data-sentry-element="unknown" data-sentry-component="EventDialogProvider" data-sentry-source-file="EventDialogProvider.tsx">
      {children}
    </EventDialogContext.Provider>;
}
export const EventDialogContext = React.createContext<EventDialogContextType>({} as EventDialogContextType);
export function useEventDialog() {
  const context = React.useContext(EventDialogContext);
  if (!context) {
    throw new Error("useEventDialog must be used within an EventDialogProvider");
  }
  return context;
}