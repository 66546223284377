"use client";

import { trpc } from "@/providers/TrpcProvider";
import { GetStockChartDataReturnType } from "@/server/providers/tickers";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { createContext, useEffect, useState } from "react";
import { useCurrentPrice } from "../../_components/CurrentPriceProvider";
dayjs.extend(utc);
dayjs.extend(timezone);
export type ChartDataContextType = ReturnType<typeof calculateChartData> & {
  isLoading: boolean;
  period: "day" | "week" | "month" | "6M" | "ytd" | "year" | "5year" | "all";
  setPeriod: (period: "day" | "week" | "month" | "6M" | "ytd" | "year" | "5year" | "all") => void;
  tooltipDateFormat: string;
};
export const ChartDataContext = createContext<ChartDataContextType>({} as ChartDataContextType);
export default function ChartDataProvider({
  children,
  ticker,
  response: initialResponse
}: {
  children: React.ReactNode;
  ticker: string;
  response: GetStockChartDataReturnType;
}) {
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [period, setPeriod] = useState<"day" | "week" | "month" | "6M" | "ytd" | "year" | "5year" | "all">("6M");
  const {
    data,
    isFetching: isLoading
  } = trpc.tickers.getStockChartData.useQuery({
    ticker,
    period
  }, {
    initialData: initialResponse,
    refetchOnWindowFocus: false,
    retry: 3
  });
  const {
    currentPrice,
    isLoading: isCurrentPriceLoading
  } = useCurrentPrice();
  const value = calculateChartData(data, period, currentPrice);
  useEffect(() => {
    setShouldRefetch(data?.isMarketOpen ?? false);
  }, [data?.isMarketOpen]);
  return <ChartDataContext.Provider value={{
    ...value,
    isLoading,
    period,
    setPeriod,
    tooltipDateFormat: getTooltipDateFormatForPeriod(period)
  }} data-sentry-element="unknown" data-sentry-component="ChartDataProvider" data-sentry-source-file="ChartDataProvider.tsx">
      {children}
    </ChartDataContext.Provider>;
}
const calculateChartData = (data: GetStockChartDataReturnType, period: string, currentPrice: number | null) => {
  const closeTime = dayjs(data.closeTime);
  const xAxisDateFormat = getXAxisDateFormatForPeriod(period);
  const chartData = data.timeseriesData?.map((item, index) => ({
    time: dayjs(item.t).format(xAxisDateFormat),
    // value: (index === 0 ? item.o : item.c) as number | null,
    value: item.c as number | null,
    t: item.t
  })) || [];
  let lastDate = dayjs(data.timeseriesData?.[data.timeseriesData.length - 1]?.t);
  if (period === "day") {
    while (lastDate && dayjs(lastDate).isBefore(closeTime.subtract(1, "minute"))) {
      lastDate = dayjs(lastDate).add(1, "minute");
      chartData.push({
        time: lastDate.format(xAxisDateFormat),
        value: null,
        t: lastDate.toDate().getTime()
      });
    }
  }
  const {
    firstNonNullValue,
    todayChange
  } = data;
  let change: number;
  switch (period) {
    case "day":
      change = ((currentPrice || data.previousClose) - data.previousClose) / data.previousClose;
      break;
    default:
      change = ((currentPrice || firstNonNullValue.c) - firstNonNullValue.c) / firstNonNullValue.c;
  }
  const chartColor = chartData.length > 0 ? change < 0 ? "#ef4444" : "#10b981" : "#10b981";
  return {
    ...data,
    chartData,
    chartColor,
    todayChange,
    change,
    timeseriesData: data.timeseriesData,
    previousClose: data.previousClose,
    xAxisDateFormat
  };
};
const getXAxisDateFormatForPeriod = (period: string) => {
  switch (period) {
    case "day":
      return "h:mm A";
    case "week":
      return "MMM D";
    case "month":
      return "MMM D";
    case "6M":
      return "MMM YY";
    case "ytd":
      const today = dayjs();
      const startOfYear = today.startOf("year");
      const diff = today.diff(startOfYear, "days");
      if (diff < 31) return "MMM D";
      return "MMM YY";
    case "year":
      return "MMM YY";
    case "5year":
      return "MM/YY";
    case "all":
      return "YYYY";
    default:
      return "MMM YY";
  }
};
const getTooltipDateFormatForPeriod = (period: string) => {
  switch (period) {
    case "day":
      return "MMM D, YYYY hh:mm A";
    case "week":
      return "MMM D, YYYY hh:mm A";
    case "month":
      return "MMM D, YYYY";
    case "6M":
      return "MMM D, YYYY";
    case "ytd":
      const today = dayjs();
      const startOfYear = today.startOf("year");
      const diff = today.diff(startOfYear, "days");
      if (diff < 31) return "MMM D h:mm A";
      return "MMM D, YYYY";
    case "year":
      return "MMM D, YYYY";
    case "5year":
      return "MMM D, YYYY";
    case "all":
      return "MMM D, YYYY";
    default:
      return "MMM D, YYYY";
  }
};