"use client";

import dynamic from "next/dynamic";
import { EventData } from "../../_components/EventDialogProvider";
const DynamicStockChart = dynamic(() => import("./StockChart"), {
  ssr: false
});
export default function WrappedStockChart({
  height = "320px",
  eventsForAnnotation = []
}: {
  height?: string;
  eventsForAnnotation?: EventData[];
}) {
  return <DynamicStockChart height={height} eventsForAnnotation={eventsForAnnotation} data-sentry-element="DynamicStockChart" data-sentry-component="WrappedStockChart" data-sentry-source-file="WrappedStockChart.tsx" />;
}