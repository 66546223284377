"use client";

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import Link from "next/link";
import * as React from "react";
import { EventData, useEventDialog } from "./EventDialogProvider";
export interface EventDialogProps {
  event: EventData;
  isAuthenticated: boolean;
  children: React.ReactNode;
}
export function EventDialog({
  event,
  isAuthenticated,
  children
}: EventDialogProps) {
  const {
    isOpen,
    setIsOpen,
    eventData,
    setHoveredEventId: setHoveredEvent
  } = useEventDialog();

  // Use the eventData from context instead of the prop for dialog content
  const displayEvent = eventData || event;
  const {
    title,
    summary,
    impact,
    sources
  } = displayEvent;
  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setHoveredEvent(null);
    }
  };
  return <Dialog open={isAuthenticated && isOpen} onOpenChange={onOpenChange} data-sentry-element="Dialog" data-sentry-component="EventDialog" data-sentry-source-file="EventDialog.tsx">
      <DialogTrigger asChild onClick={() => {
      if (isAuthenticated) setIsOpen(true);
    }} data-sentry-element="DialogTrigger" data-sentry-source-file="EventDialog.tsx">
        {children}
      </DialogTrigger>
      <DialogContent className={cn("max-h-[80vh] overflow-y-auto")} data-sentry-element="DialogContent" data-sentry-source-file="EventDialog.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="EventDialog.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="EventDialog.tsx">{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className="font-semibold text-foreground" data-sentry-element="DialogDescription" data-sentry-source-file="EventDialog.tsx">
          Summary:
        </DialogDescription>
        <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="EventDialog.tsx">{summary}</DialogDescription>
        <DialogDescription className="font-semibold text-foreground" data-sentry-element="DialogDescription" data-sentry-source-file="EventDialog.tsx">
          Impact:
        </DialogDescription>
        <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="EventDialog.tsx">{impact}</DialogDescription>
        {sources && sources.length > 0 && <>
            <DialogDescription className="font-semibold text-foreground">
              Sources:
            </DialogDescription>
            {sources.map((source, i) => <DialogDescription key={i}>
                <Link href={source.url} target="_blank" rel="noopener noreferrer" className="hover:underline">
                  {source.name}
                </Link>
              </DialogDescription>)}
          </>}
      </DialogContent>
    </Dialog>;
}